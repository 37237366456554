@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply px-10 py-2 my-0 font-bold tracking-widest uppercase border-2 border-black font-chakra hover:bg-black hover:text-white;
  }
}

.item-container {
  @apply flex flex-col justify-between w-full space-y-6 text-2xl text-white uppercase md:flex-row md:space-y-0 md:space-x-8 md:flex-wrap;
}
.project-container {
  @apply flex flex-col justify-between w-full space-y-6 text-2xl text-white uppercase md:flex-row md:space-y-0 md:space-x-8;
}

.item {
  @apply relative overflow-hidden md:w-1/5;
}
.nav-item {
  @apply mx-2 group-hover:border-b group-hover:border-blue-50;
}

.item-gradient {
  @apply absolute top-0 bottom-0 right-0 left-0 bg-gradient-to-b from-transparent to-gray-900 group-hover:from-gray-50 group-hover:to-white group-hover:opacity-70;
}

#header {
  position: relative;
  background-image: url("../public/images/keyboard.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 576px) {
  #header {
    background-image: url("../public/images/keyboard.jpg");
    background-position: center;
  }
}

.btn {
  margin-top: auto;
}

.card.shadow-animate:hover {
  box-shadow: 0px 0px 20px 10px #48abe0;
  animation: shadows 3s infinite;
}

.card:hover {
  color: #48abe0;
  border-radius: 10px;
  box-shadow: 0 0 50px 15px #48abe0;
}

/* Hamburger Menu */
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  width: 24px;
  height: 2px;
  top: 0;
  left: 0;
  background: #fff;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translateX(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
}

@keyframes shadows {
  0% {
    text-shadow: #48abe0 0 0 10px;
    box-shadow: 0px 0px 20px 10px #48abe0;
  }
  50% {
    text-shadow: blueviolet 0 0 10px;
    box-shadow: 0px 0px 20px 10px blueviolet;
  }
  75% {
    text-shadow: rebeccapurple 0 0 10px;
    box-shadow: 0px 0px 20px 10px rebeccapuprle;
  }
  100% {
    text-shadow: #48abe0 0 0 10px;
    box-shadow: 0px 0px 20px 10px #48abe0;
  }
}
